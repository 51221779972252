import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const initialState: AuthStoreState = {
	authToken: undefined,
};

export const useAuthStore = create<AuthStoreState & AuthStoreActions>()(
	persist(
		devtools((set) => ({
			...initialState,
			setAuthToken: (authToken) => set({ authToken }),
			reset: () => set(initialState),
		})),
		{
			name: "auth-store",
		},
	),
);

type AuthStoreState = {
	authToken?: string;
};

type AuthStoreActions = {
	setAuthToken: (authToken: string) => void;
	reset: () => void;
};
