import { createContext, useContext } from "react";
import { useAuthStore } from "@/stores/authStore.tsx";
import { trpc } from "@/utils";
import { useNavigate } from "react-router-dom";

import type { SecurityCodeVerificationDto } from "@repos/sagamm-dtos/src/dtos/login.dto.ts";

const AuthContext = createContext({
	authToken: undefined as string | undefined,
	loginAction: async (data: SecurityCodeVerificationDto) => {
		console.error("AuthContext not initialized", data);
	},
	logOut: () => {},
});
export const AuthProvider = ({ children }) => {
	const navigate = useNavigate();
	const sendSecurityCodeMutation =
		trpc.authentication.VerifySecurityCode.useMutation();

	const { authToken, setAuthToken, reset } = useAuthStore((state) => state);
	const loginAction = async (data: SecurityCodeVerificationDto) => {
		const res = await sendSecurityCodeMutation.mutateAsync(data);
		if (res.token) {
			setAuthToken(res.token);
			navigate("/formulaire");
			return;
		}
	};

	const logOut = () => {
		reset();
		navigate("/");
	};
	return (
		<AuthContext.Provider value={{ authToken, loginAction, logOut }}>
			{children}
		</AuthContext.Provider>
	);
};
export default AuthProvider;

export const useAuth = () => {
	return useContext(AuthContext);
};
