import Layout from "@/layouts/Layout.tsx";
import { DataTableDisplayIframe } from "@/pages/DataTableDisplayIframe.tsx";
import { LoginPage } from "@/pages/LoginPage.tsx";

export const publicRoutes = [
	{
		path: "/*",
		element: <Layout />,
		children: [
			{
				path: "/*",
				element: <LoginPage />,
			},
		],
	},
	{
		path: "/tableIframe",
		element: <DataTableDisplayIframe />,
	},
];
