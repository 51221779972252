import { CustomSpinner } from "@/components/CustomSpinner.tsx";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert.tsx";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@/components/ui/table.tsx";
import { useFileQuery } from "@/hooks/fileQuery.ts";
import { cn } from "@/utils";
import { AlertCircle } from "lucide-react";

export const DataTableDisplayIframe = () => {
	const query = useFileQuery();
	//transpose query.data.tableData
	const transposedData = query.data?.tableData.map((_, colIndex) =>
		query.data.tableData.map((row) => row.data[colIndex]),
	);
	if (query.isError) {
		return (
			<Alert variant="destructive">
				<AlertCircle className="h-4 w-4" />
				<AlertTitle>Erreur</AlertTitle>
				<AlertDescription>
					{query.error.message
						? query.error.message
						: "Le fichier n&apos;a pas pu être chargé, vérifiez que le fichier est correct et réessayez."}
				</AlertDescription>
			</Alert>
		);
	}
	if (query.isLoading) {
		return <CustomSpinner />;
	}
	return (
		<Table className="rounded">
			<TableBody>
				<TableRow>
					{query.data.tableData.map((row) => (
						<TableHead
							className="border-l bg-blue-500 text-white"
							key={row.columnHeader}
						>
							{row.columnHeader}
						</TableHead>
					))}
				</TableRow>
				{transposedData?.map((row, index) =>
					//test if all row elements are undefined
					row.every((el) => el === undefined) ? null : (
						<TableRow
							key={row[0]}
							className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
						>
							{row.map((cell) => (
								<TableCell key={cell} className="border-l">
									{cell}
								</TableCell>
							))}
						</TableRow>
					),
				)}

				{/*empty row for extra data */}
				<TableRow>
					<TableHead colSpan={2} />
				</TableRow>
				{query.data.extraData?.map((row, index) => (
					<TableRow
						className={cn(
							"border-t",
							index % 2 === 0 ? "bg-gray-100" : "bg-white",
						)}
						key={row?.label}
					>
						<TableHead colSpan={2}>{row?.label}</TableHead>
						<TableCell className="border-l" colSpan={1}>
							{row?.value}
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
};
