import { Toaster } from "@/components/ui/toaster.tsx";
import { Outlet } from "react-router-dom";

import complianceLogo from "/src/assets/compliance_logo.png";
import sagammLogo from "/src/assets/sagamm_logo.png";

const FormLayout = () => {
	return (
		<div className="h-screen">
			<img
				src={sagammLogo}
				alt="sagamm logo"
				className="w-32 lg:fixed lg:left-20 lg:top-8 lg:z-10 "
			/>
			<img
				src={complianceLogo}
				alt="compliance logo"
				className="w-32 lg:fixed lg:right-20 lg:top-8 lg:z-10"
			/>

			<main className="flex h-full items-center justify-center py-0">
				<Outlet />
			</main>
			<Toaster />
		</div>
	);
};

export default FormLayout;
