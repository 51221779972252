import { Toaster } from "@/components/ui/toaster.tsx";
import { Outlet } from "react-router-dom";

import complianceLogo from "/src/assets/compliance_logo.png";

const Layout = () => {
	return (
		<div className="h-screen">
			<img
				src={complianceLogo}
				alt="compliance logo"
				className="w-32 lg:fixed lg:right-20 lg:top-8 lg:z-10"
			/>

			<main className="flex h-full items-center justify-center py-0">
				<Outlet />
			</main>
			<Toaster />
		</div>
	);
};

export default Layout;
