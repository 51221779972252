import type { ReactNode } from "react";
import { CustomSpinner } from "@/components/CustomSpinner.tsx";
import { useAuth } from "@/providers/AuthProvider.tsx";
import { trpc } from "@/utils";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
	children: ReactNode;
}

export const PrivateRoute = ({ children }: ProtectedRouteProps) => {
	const auth = useAuth();

	if (!auth.authToken) return <Navigate to="/" />;
	const verifyTokenQuery = trpc.authentication.verifyToken.useQuery({
		token: auth.authToken,
	});
	if (verifyTokenQuery.isLoading) return <CustomSpinner />;
	if (verifyTokenQuery.isError) return <Navigate to="/" />;

	return children;
};
