import { trpc } from "@/utils";
import { useLocation } from "react-router-dom";

export const useFileQuery = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	// access query parameters
	const authToken = queryParams.get("authToken");
	const year = queryParams.get("year") as "2024" | "2023-2022";
	const file = queryParams.get("file");
	if (!authToken) {
		throw new Error("No authToken provided");
	}
	switch (file) {
		case "particulier":
			return trpc.turnoverPartition.getParticulierData.useQuery(
				{
					token: authToken,
					year,
				},
				{
					retry: false,
				},
			);
		case "pef":
			return trpc.turnoverPartition.getPefData.useQuery(
				{
					token: authToken,
					year,
				},
				{
					retry: false,
				},
			);
		case "pj":
			return trpc.turnoverPartition.getPjData.useQuery(
				{
					token: authToken,
					year,
				},
				{
					retry: false,
				},
			);
		case "vie":
			return trpc.turnoverPartition.getVieData.useQuery(
				{
					token: authToken,
					year,
				},
				{
					retry: false,
				},
			);
		case "pc":
			return trpc.turnoverPartition.getPCData.useQuery(
				{
					token: authToken,
					year,
				},
				{
					retry: false,
				},
			);
		default:
			throw new Error("Invalid file type");
	}
};
