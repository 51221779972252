import { useState } from "react";
import { LoginCardContent } from "@/components/LoginCardContent.tsx";
import { SecurityCodeCardContent } from "@/components/SecurityCodeCardContent.tsx";
import { Card, CardHeader, CardTitle } from "@/components/ui/card.tsx";
import { toast } from "@/components/ui/use-toast.ts";
import { trpc } from "@/utils";
import * as z from "zod";

export const loginSchema = z.object({
	email: z.string().email(),
});
export const LoginPage = () => {
	const [displaySecurityCodeCard, setDisplaySecurityCodeCard] = useState(false);
	const [inputEmail, setInputEmail] = useState<string>("");
	const loginWithEmailMutation = trpc.authentication.login.useMutation({
		onSuccess: () => setDisplaySecurityCodeCard(true),
		onError: () => {
			toast({
				title: "Erreur",
				description: "L'email n'est pas valide",
				variant: "destructive",
			});
		},
	});
	const handleResendCode = () => {
		setDisplaySecurityCodeCard(false);
	};

	async function onVerifyEmailSubmit(data: z.infer<typeof loginSchema>) {
		setInputEmail(data.email);
		await loginWithEmailMutation.mutateAsync(data);
	}

	return (
		<div className="flex h-full w-full items-center justify-center bg-gray-100">
			<Card className="max-w-xl gap-4 px-20 py-6">
				<CardHeader className="space-y-1">
					<CardTitle className="text-2xl text-gray-600">
						Connectez-vous pour continuer
					</CardTitle>
				</CardHeader>
				{!displaySecurityCodeCard ? (
					<LoginCardContent
						isLoading={loginWithEmailMutation.isLoading}
						onSubmit={onVerifyEmailSubmit}
						defaultEmailValue={inputEmail}
					/>
				) : (
					<SecurityCodeCardContent
						handleResendCode={handleResendCode}
						inputEmail={inputEmail}
					/>
				)}
			</Card>
		</div>
	);
};
