import { FilloutFullScreenEmbed } from "@fillout/react";

import "@fillout/react/style.css";

import { useAuth } from "@/providers/AuthProvider.tsx";

function FilloutSagammFormPage() {
	const { authToken } = useAuth();
	return (
		<FilloutFullScreenEmbed
			filloutId={import.meta.env.VITE_PUBLIC_FILLOUT_FORM_ID}
			parameters={{
				authToken,
			}}
			inheritParameters
			domain={"forms.bysur.com"}
		/>
	);
}

export default FilloutSagammFormPage;
