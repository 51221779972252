import { privateRoutes } from "@/routes/private.tsx";
import { publicRoutes } from "@/routes/public.tsx";
import * as Sentry from "@sentry/react";
import { useRoutes } from "react-router-dom";

const useSentryRoutes = Sentry.wrapUseRoutes(useRoutes);

const Routes = () => {
	const routes = useSentryRoutes([...privateRoutes, ...publicRoutes]);
	return routes;
};

export const AppRoutes = () => {
	return <Routes />;
};
