import { useState } from "react";
import { Button } from "@/components/ui/button.tsx";
import { CardContent, CardFooter } from "@/components/ui/card.tsx";
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSlot,
} from "@/components/ui/input-otp.tsx";
import { Label } from "@/components/ui/label.tsx";
import { toast } from "@/components/ui/use-toast.ts";
import { useAuth } from "@/providers/AuthProvider.tsx";
import { CgSpinner } from "react-icons/cg";

interface SecurityCodeCardContentProps {
	handleResendCode: () => void;
	inputEmail: string;
}

export const SecurityCodeCardContent = ({
	handleResendCode,
	inputEmail,
}: SecurityCodeCardContentProps) => {
	const [value, setValue] = useState<number | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const loginAction = useAuth().loginAction;
	const handleSubmit = async () => {
		if (value === undefined) return;
		try {
			await loginAction({ email: inputEmail, securityCode: value });
		} catch (err) {
			setIsLoading(false);
			toast({
				title: "Erreur",
				description: "Le code de sécurité est incorrect",
				variant: "destructive",
			});
		}
	};
	return (
		<>
			<CardContent>
				<div className="grid gap-2">
					<Label htmlFor="email" className="font-semibold text-gray-600">
						Vérifiez votre e-mail et fournissez le code ci-dessous
					</Label>
					<InputOTP
						maxLength={6}
						value={value?.toString()}
						onChange={(value) => setValue(Number(value))}
					>
						<InputOTPGroup>
							<InputOTPSlot index={0} />
							<InputOTPSlot index={1} />
							<InputOTPSlot index={2} />
							<InputOTPSlot index={3} />
							<InputOTPSlot index={4} />
							<InputOTPSlot index={5} />
						</InputOTPGroup>
					</InputOTP>
					<p className="text-sm text-gray-600">
						Assurez-vous de vérifier votre dossier spam.
					</p>
				</div>
			</CardContent>
			<CardFooter>
				<div className=" flex w-full flex-row justify-between">
					<Button
						className="text-gray-400"
						variant="ghost"
						onClick={handleResendCode}
					>
						Renvoyer le code
					</Button>
					<Button
						className="bg-blue-500 hover:bg-blue-600"
						onClick={async () => {
							setIsLoading(true);
							await handleSubmit();
						}}
						disabled={value !== undefined && value.toString().length < 6}
					>
						{isLoading && <CgSpinner className="mr-2 h-4 w-4 animate-spin" />}
						Vérifier le code
					</Button>
				</div>
			</CardFooter>
		</>
	);
};
