import FormLayout from "@/layouts/FormLayout.tsx";
import { PrivateRoute } from "@/lib/PrivateRoute.tsx";
import FilloutSagammFormPage from "@/pages/FilloutSagammFormPage.tsx";

export const privateRoutes = [
	{
		path: "/*",
		element: (
			<PrivateRoute>
				<FormLayout />{" "}
			</PrivateRoute>
		),
		children: [
			{
				path: "formulaire",
				element: <FilloutSagammFormPage />,
			},
		],
	},
];
