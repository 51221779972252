import type * as z from "zod";
import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button.tsx";
import { CardContent, CardFooter } from "@/components/ui/card.tsx";
import { Checkbox } from "@/components/ui/checkbox.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Label } from "@/components/ui/label.tsx";
import { loginSchema } from "@/pages/LoginPage.tsx";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { CgSpinner } from "react-icons/cg";

interface LoginCardContentProps {
	isLoading: boolean;
	onSubmit: (data: z.infer<typeof loginSchema>) => Promise<void>;
	defaultEmailValue: string;
}

export const LoginCardContent = ({
	isLoading,
	onSubmit,
	defaultEmailValue,
}: LoginCardContentProps) => {
	const methods = useForm<z.infer<typeof loginSchema>>({
		resolver: zodResolver(loginSchema),
		defaultValues: {
			email: defaultEmailValue,
		},
	});
	//this useEffect is used to check if the defaultEmailValue is set and if it is, it will set the termsIsChecked to true since it means
	//the user has already checked the terms and conditions
	useEffect(() => {
		if (defaultEmailValue) setTermsIsChecked(true);
	}, []);
	const [termsIsChecked, setTermsIsChecked] = useState(false);
	return (
		<form onSubmit={methods.handleSubmit(onSubmit)}>
			<CardContent>
				<div className="grid gap-2">
					<Label htmlFor="email" className="font-normal text-gray-600">
						Email
					</Label>
					<Input
						id="email"
						type="email"
						autoCapitalize="none"
						autoComplete="email"
						autoCorrect="off"
						disabled={isLoading}
						{...methods.register("email")}
					/>
				</div>
			</CardContent>
			<CardFooter>
				<div className="flex flex-col items-center space-y-6">
					<div className="flex items-center space-x-4">
						<Checkbox
							id="terms"
							checked={termsIsChecked}
							className="h-4 w-4 cursor-pointer rounded border border-gray-400 text-blue-500
                                  data-[state=checked]:bg-blue-500
                                  "
							onClick={() => setTermsIsChecked(!termsIsChecked)}
						/>
						<label
							htmlFor="terms"
							className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
						>
							En cliquant sur le bouton vérifier l&apos;email, vous acceptez les{" "}
							<a className="font-medium text-red-500" href="#" target="_blank">
								{" "}
								Conditions d&apos;utilisation
							</a>
							,
							<a className="font-medium text-red-500" href="#" target="_blank">
								{" "}
								la Politique de confidentialité
							</a>
							, et
							<a className="font-medium text-red-500" href="#" target="_blank">
								{" "}
								la Politique relative aux cookies
							</a>{" "}
							de SAGAMM.
						</label>
					</div>
					<Button
						className="w-full bg-blue-500 hover:bg-blue-600"
						disabled={!termsIsChecked}
					>
						{isLoading && <CgSpinner className="mr-2 h-4 w-4 animate-spin" />}
						Vérifier l&apos;email
					</Button>
				</div>
			</CardFooter>
		</form>
	);
};
